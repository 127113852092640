import React, { useEffect, useState, useRef } from 'react'
import "./style.scss"

export default function Dropdown({ optionList, setData, data, fieldName, disabled = false,
  children, selectFirst = false, dropdownClass = "", lock, setLock }) {

  const [isOptionsOpen, setIsOptionOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(-1)
  const ref = useRef(null);


  const hadndleAddData = (option, index) => {
    setSelectedOption(index);
    setIsOptionOpen(false);

    setData((prev) => ({
      ...prev,
      [fieldName]: option.value,
      assetItems: fieldName === "sachets"
        ? [
          {
            sku: option.sku,
          },
        ]
        : prev.assetItems,
    }));
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOptionOpen(false)
    }
  };

  useEffect(() => {
    if (selectFirst && data[fieldName] === "") {
      // console.log(optionList[0])
      hadndleAddData(optionList[0], 0)
    }
  }, [data[fieldName]])

  useEffect(() => {
    if (selectFirst && selectedOption === -1) {
      // console.log(fieldName)
      hadndleAddData(optionList[0], 0)
    }

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
    // eslint-disable-next-line
  }, [])


  return (
    <div className={`dropdown ${isOptionsOpen ? "show" : ""} ${selectedOption !== -1 && optionList.length > 2 ? "selected" : ""} ${dropdownClass}`} ref={ref} disabled={disabled || optionList.length < 2}>
      <button type='button' onClick={() => setIsOptionOpen(prev => !prev)} disabled={disabled || optionList.length < 2}>
        <div className="button-content">
          {optionList.length < 2 ?
            optionList[0].name : selectedOption !== -1 ?
              data[fieldName] !== "" ? optionList.filter(e => e.value === data[fieldName])[0].name : children : children
          }
        </div>
      </button>
      <ul className="options" tabIndex={-1}>
        {optionList.map((option, index) => (
          <li tabIndex={0} key={index} onClick={() => { hadndleAddData(option, index) }}>
            {option.name}
          </li>
        ))}
      </ul>
    </div>
  )
}
