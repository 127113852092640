import React from 'react'
import "./style.scss"

export default function Footer() {
  return (
    <footer className="footer">
      <p className="copyrights">© 2022 British American Tobacco. All rights reserved.</p>
    </footer>
  )
}
