import React from 'react'
import ReactModal from 'react-modal';
import "./style.scss"

ReactModal.setAppElement('#root');

export default function Modal({ isOpen, setIsOpen, children, resetForm }) {
  return (
    <ReactModal isOpen={isOpen} setIsOpen={setIsOpen} overlayClassName={"modal overlay"} className={"modal content"} preventScroll={true}>
      <div className='modal-wrapper'>
        <div className="exit-button">
          <button onClick={() => { setIsOpen(false); resetForm() }}>
            <div className="content"></div>
          </button>
        </div>
        {children}
      </div>
    </ReactModal>
  )
}
