export const webformData = {
  "store_id_austria": [
    {
      "value": "Event",
      "name": "Event"
    },
    {
      "value": "HoReCa",
      "name": "HoReCa"
    },
    {
      "value": "Other",
      "name": "Other"
    },
  ],
  "gender": [
    {
      "value": "Keine Angabe",
      "name": "Keine Angabe"
    },
    {
      "value": "Herr",
      "name": "Herr"
    },
    {
      "value": "Frau",
      "name": "Frau"
    },
    {
      "value": "Divers",
      "name": "Divers"
    },

  ],
  "mobilePrefixes": [
    {
      "value": "43",
      "name": "+43"
    },
    {
      "value": "49",
      "name": "+49"
    },
    {
      "value": "36",
      "name": "+36"
    },
    {
      "value": "420",
      "name": "+420"
    },
    {
      "value": "421",
      "name": "+421"
    },
    {
      "value": "41",
      "name": "+41"
    },
    {
      "value": "386",
      "name": "+386"
    },
    {
      "value": "387",
      "name": "+387"
    },
    {
      "value": "381",
      "name": "+381"
    },
    {
      "value": "385",
      "name": "+385"
    },
    {
      "value": "48",
      "name": "+48"
    },
    {
      "value": "40",
      "name": "+40"
    }
  ],
  "brand": [
    {
      "value": "Velo",
      "name": "Add Velo Sampling"
    }
  ]
  ,
  "sachets": [
    {
      "value": null,
      "name": "None",
      "sku": "none",
      "flavour": ""
    },
    {
      "value": "Berry Frost",
      "name": "Berry Frost",
      "sku": "8843585126720",
      "flavour": "Polar Mint"
    },
    {
      "value": "Berry Frost Mini",
      "name": "Berry Frost Mini",
      "sku": "8843593843008",
      "flavour": "Tropic Breeze"
    },
    {
      "value": "Cool Storm Max",
      "name": "Cool Storm Max",
      "sku": "8843591385408",
      "flavour": "Polar Mint"
    },
    {
      "value": "Cool Storm X-Strong",
      "name": "Cool Storm X-Strong",
      "sku": "8843586404672",
      "flavour": "Berry Frost"
    },
    {
      "value": "Freeze X-Strong",
      "name": "Freeze X-Strong",
      "sku": "8843586797888",
      "flavour": "Polar Mint"
    },
    {
      "value": "Freeze X-Strong - Hahnenkamm Limited Edition",
      "name": "Freeze X-Strong - Hahnenkamm Limited Edition",
      "sku": "8955122221376",
      "flavour": "Ruby Berry"
    },
    {
      "value": "Ice Cool",
      "name": "Ice Cool",
      "sku": "8843585978688",
      "flavour": "Berry Frost"
    },
    {
      "value": "Ice Cool - Hahnenkamm Limited Edition",
      "name": "Ice Cool - Hahnenkamm Limited Edition",
      "sku": "8955124121920",
      "flavour": "Ice Cool"
    },
    {
      "value": "Mint Mini",
      "name": "Mint Mini",
      "sku": "8843596333376",
      "flavour": "Freeze"
    },
    {
      "value": "Peppermint Storm",
      "name": "Peppermint Storm",
      "sku": "9184253051200",
      "flavour": "Peppermint Storm"
    },
    {
      "value": "Polar Mint Mini",
      "name": "Polar Mint Mini",
      "sku": "8843592859968",
      "flavour": "Urban Vibe"
    },
    {
      "value": "Polar Mint Mini - Hahnenkamm Limited Edition",
      "name": "Polar Mint Mini - Hahnenkamm Limited Edition",
      "sku": "8955124777280",
      "flavour": "X-Freeze"
    },
    {
      "value": "Strawberry Ice",
      "name": "Strawberry Ice",
      "sku": "9297056923968",
      "flavour": "Strawberry Ice"
    },
    {
      "value": "Tropic Breeze",
      "name": "Tropic Breeze",
      "sku": "8843587256640",
      "flavour": "X-Freeze"
    },
    {
      "value": "Tropic Breeze - Hahnenkamm Limited Edition",
      "name": "Tropic Breeze - Hahnenkamm Limited Edition",
      "sku": "8955132150080",
      "flavour": "Yellow Root"
    },
    {
      "value": "Tropical Ice",
      "name": "Tropical Ice",
      "sku": "9297061052736",
      "flavour": "Tropical Ice"
    },
    {
      "value": "Watermelon Mint",
      "name": "Watermelon Mint",
      "sku": "8843588436288",
      "flavour": "Yellow Root"
    },
    {
      "value": "Wintry Watermelon",
      "name": "Wintry Watermelon",
      "sku": "8843588436288",
      "flavour": "Wintry Watermelon"
    },
    {
      "value": "X-Freeze Max",
      "name": "X-Freeze Max",
      "sku": "8843591778624",
      "flavour": "Yellow Root"
    },
    {
      "value": "X-Freeze Max - Hahnenkamm Limited Edition",
      "name": "X-Freeze Max - Hahnenkamm Limited Edition",
      "sku": "8955132739904",
      "flavour": "Yellow Root"
    },
    {
      "value": "X-Freeze Ultra",
      "name": "X-Freeze Ultra",
      "sku": "8843590664512",
      "flavour": "Yellow Root"
    },
    {
      "value": "Yellow Root",
      "name": "Yellow Root",
      "sku": "8843592270144",
      "flavour": "Yellow Root"
    }
  ],
  "oral": [
    {
      "value": "Velo",
      "name": "Velo"
    },
    {
      "value": "Skruf",
      "name": "Skruf"
    },
    {
      "value": "Zone-X",
      "name": "Zone-X"
    },
    {
      "value": "White Fox",
      "name": "White Fox"
    },
    {
      "value": "Thunder",
      "name": "Thunder"
    },
    {
      "value": "Shiro",
      "name": "Shiro"
    },
  ],
  "category_before_austria": [
    {
      "value": "FMC",
      "name": "FMC"
    },
    {
      "value": "Oral",
      "name": "Oral"
    },
    {
      "value": "Vape",
      "name": "Vape"
    },
    {
      "value": "THP",
      "name": "THP"
    },
    {
      "value": "Other",
      "name": "Other"
    },
  ],
  "FMC": [
    {
      "value": "LUCKY STRIKE",
      "name": "LUCKY STRIKE"
    },
    {
      "value": "PALL MALL",
      "name": "PALL MALL"
    },
    {
      "value": "PARISIENNE",
      "name": "PARISIENNE"
    },
    {
      "value": "HB",
      "name": "HB"
    },
    {
      "value": "VOGUE",
      "name": "VOGUE"
    },
    {
      "value": "MARLBORO",
      "name": "MARLBORO"
    },
    {
      "value": "CHESTERFIELD",
      "name": "CHESTERFIELD"
    },
    {
      "value": "PHILIP MORRIS",
      "name": "PHILIP MORRIS"
    },
    {
      "value": "L&M",
      "name": "L&M"
    },
    {
      "value": "Memphis",
      "name": "Memphis"
    },
    {
      "value": "B&H",
      "name": "B&H"
    },
    {
      "value": "WINSTON",
      "name": "WINSTON"
    },
    {
      "value": "CAMEL",
      "name": "CAMEL"
    },
    {
      "value": "MEINE SORTE",
      "name": "MEINE SORTE"
    },
    {
      "value": "GLAMOUR",
      "name": "GLAMOUR"
    },
    {
      "value": "SMART",
      "name": "SMART"
    },
    {
      "value": "AMERICAN SPIRIT",
      "name": "AMERICAN SPIRIT"
    },
    {
      "value": "JPS",
      "name": "JPS"
    },
    {
      "value": "GAULOISES",
      "name": "GAULOISES"
    },
    {
      "value": "DAVIDOFF",
      "name": "DAVIDOFF"
    },
    {
      "value": "WEST",
      "name": "WEST"
    },
    {
      "value": "ERNTE 23",
      "name": "ERNTE 23"
    },
    {
      "value": "BATTON",
      "name": "BATTON"
    },
    {
      "value": "PUEBLO",
      "name": "PUEBLO"
    },
    {
      "value": "KARELIA",
      "name": "KARELIA"
    },
    {
      "value": "KING",
      "name": "KING"
    },
    {
      "value": "CORSET",
      "name": "CORSET"
    },
    {
      "value": "PEPE",
      "name": "PEPE"
    },
    {
      "value": "ALLURE",
      "name": "ALLURE"
    },
  ],
  "THP": [
    {
      "value": "IQOS",
      "name": "IQOS"
    },
    {
      "value": "Glo",
      "name": "Glo"
    },
  ],
  "hostessCodes": "^(HT1|HT2|HT3|HT4|HT5|HT6|HT7|ET1|ET2|ET3|ET4|ET5|ET6|ET7|OT1|OT2|OT3|OT4|OT5|AMSE|BRHAU|CRBE|DAHE|JETO|KOHA|MITR|NISA|RAVA|BLNK|AS00|AS01|AS02|AS03|AS04|AS05|AS06|AS07|AS08|AS09|AS10|AS11|AS12|AS13|AS14|AS15|AS16|AS17|AS18|AS19|AS20|AS21|AS22|AS23|AS24|AS25|AS26|AS27|AS28|AS29|AS30|AS31|AS32|AS33|AS34|AS35|AS36|AS37|AS38|AS39|AS40|AS41|AS42|AS43|AS44|AS45|AS46|AS47|AS48|AS49|AS50|AS51|AS52|AS53|AS54|AS55|AS56|AS57|AS58|AS59|AS60|AS61|AS62|AS63|AS64|AS65|AS66|AS67|AS68|AS69|AS70|AS71|AS72|AS73|AS74|AS75|AS76|AS77|AS78|AS79|AS80|AS81|AS82|AS83|AS84|AS85|AS86|AS87|AS88|AS89|AS90|AS91|AS92|AS93|AS94|AS95|AS96|AS97|AS98|AS99|PS00|PS01|PS02|PS03|PS04|PS05|PS06|PS07|PS08|PS09|PS10|PS11|PS12|PS13|PS14|PS15|PS16|PS17|PS18|PS19|PS20|PS21|PS22|PS23|PS24|PS25|PS26|PS27|PS28|PS29|PS30|PS31|PS32|PS33|PS34|PS35|PS36|PS37|PS38|PS39|PS40|PS41|PS42|PS43|PS44|PS45|PS46|PS47|PS48|PS49|PS50|PS51|PS52|PS53|PS54|PS55|PS56|PS57|PS58|PS59|PS60|PS61|PS62|PS63|PS64|PS65|PS66|PS67|PS68|PS69|PS70|PS71|PS72|PS73|PS74|PS75|PS76|PS77|PS78|PS79|PS80|PS81|PS82|PS83|PS84|PS85|PS86|PS87|PS88|PS89|PS90|PS91|PS92|PS93|PS94|PS95|PS96|PS97|PS98|PS99|)$"
}