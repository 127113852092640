import React, { useEffect, useState, useRef } from 'react';
import CalendarModule from 'react-calendar';
import "./style.scss";

export default function Calendar({ setData, data, children, fieldName, lock, setLock }) {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [viewDate, setViewDate] = useState(new Date(631191600000));
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const ref = useRef(null);

  // console.log(selectedDate);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsCalendarOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
    // eslint-disable-next-line
  }, []);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  return (
    <div className={`dropdown calendar-dropdown ${isCalendarOpen ? "show" : ""} ${data[fieldName] !== "" ? "selected" : ""}`} ref={ref}>
      <button type='button' onClick={() => setIsCalendarOpen(prev => !prev)}>
        <div className="button-content">
          {data.birthday ? formatDate(selectedDate) : children}
        </div>
      </button>
      <div className="calendar">
        <div className="inner">
          <div className="year-month-container">
            <div className="month wrapper">
              <button type='button' onClick={() => setViewDate(new Date(viewDate.setMonth(viewDate.getMonth() + 1)))}>
                <div className='prev' />
              </button>
              <p>{viewDate.toLocaleString("de-AT", { month: "long" })}</p>
              <button type='button' onClick={() => setViewDate(new Date(viewDate.setMonth(viewDate.getMonth() + 1)))}>
                <div className='next' />
              </button>
            </div>
            <div className="year wrapper">
              <button type='button' onClick={() => setViewDate(new Date(viewDate.setFullYear(viewDate.getFullYear() - 1)))}>
                <div className='prev' />
              </button>
              <p>{viewDate.getFullYear()}</p>
              <button type='button' onClick={() => setViewDate(new Date(viewDate.setFullYear(viewDate.getFullYear() + 1)))}>
                <div className='next' />
              </button>
            </div>
          </div>
          <CalendarModule onChange={(date) => {
            setSelectedDate(date);
            setData({ ...data, [fieldName]: formatDate(date) });
            setIsCalendarOpen(prev => !prev);
          }}
            value={selectedDate} showNeighboringMonth={false} maxDetail={"month"} minDetail={"month"}
            showNavigation={false} activeStartDate={viewDate} locale={"de-AT"}
            onActiveStartDateChange={({ activeStartDate }) => setViewDate(activeStartDate)} />
        </div>
      </div>
    </div>
  );
}
