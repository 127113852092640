import React from 'react'
import WebForm from './pages/WebForm'
import Footer from './components/Footer'

export default function MainView() {
  return (
    <>
      <div className="page-holder">
        <WebForm />
      </div>
      <Footer />
    </>
  )
}
