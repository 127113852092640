import React, { useState, useEffect, useRef } from 'react'
import "./style.scss"
import Dropdown from '../../components/Dropdown'
import Calendar from "../../components/Calendar"
import { webformData } from "../../data/webform"
import _ from 'lodash'
import Modal from "../../components/Modal"
import Statute1 from "../../../assets/Data_Policy_PromoApp.pdf"
import Statute2 from "../../../assets/Terms_of_Service_PromoApp.pdf"


const FETCH_ERROR = "fetch-error"
const GET_OTP = "get-otp"
const SUCCESS = "success"

export default function WebForm() {
  const [data, setData] = useState({
    store_id_austria: "",
    promotor_id_austria: "",
    gender: "Keine Angabe",
    firstname: "",
    lastname: "",
    email: "",
    intCode: "",
    areaCode: "",
    number: "",
    birthday: "",
    brand_austria: "",
    category_before_austria: "",
    category_austria: "",
    otp: "",
    "digital_data_agreement": false,
    "terms_austria": true,
    "marketing_consents_austria": true,
    "policy_agreement": true,
    "oral_status_austria": "Prospect",
    "hypercareOptIn": true,
    "leadsource_austria": "Promoter",
    "countryId": "1014",
    "age_verified": true,
    "data_privacy_agreement": true,
    "terms_of_service_accepted": true,
    "marketing_consent": true,
    "asset_source": "Hostess",
    "assetItems": [
      {
        "sku": "none",
      },
    ],
  })

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState("")
  const [loosedFocus, setLoosedFocus] = useState({})
  const webFormRef = useRef(null)

  useEffect(() => {
    if (modalIsOpen) {
      document.body.style.overflow = 'hidden';
      // console.log("hit")
    }

    return () => {
      document.body.style.overflow = 'initial';
    }
  }, [modalIsOpen])

  // console.log(data.birthday)


  //OTP data.intCode.length === 1 dla kierunkowych z 2 liczbami/ data.intCode.length === 2 dla kierunkowych z 3 liczbami
  const getOTP = async () => {
    const number = "+" + data.intCode + data.number

    const leadData = {
      cellphone: number,
    };

    const response = await fetch(process.env.REACT_APP_API_URL + "/lead/otp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        lead: leadData,
      }),
    });

    const content = await response.json();
    setModalIsOpen(true);
    setModalContent(content?.status?.success && content?.data ? GET_OTP : FETCH_ERROR);
  };

  const handleSubmit = async () => {
    const number = "+" + data.intCode + data.number
    
    const leadData = {
      otp: data.otp,
      birthday: data.birthday,
      cellphone: number,
      email: data.email,
      firstname: data.firstname,
      lastname: data.lastname,
      gender: data.gender,
      age_verified: true,
      data_privacy_agreement: true,
      terms_of_service_accepted: true,
      marketing_consent: true,
      asset_source: "Hostess",
      brand_austria: data.brand_austria,
      oral_status_austria: data.oral_status_austria, // Update with the correct value
      promotor_id_austria: data.promotor_id_austria,
      store_id_austria: data.store_id_austria,
      category_before_austria: data.category_before_austria,
      category_austria: data.category_austria,
      leadsource_austria: data.leadsource_austria, // Update with the correct value
      assetItems: [
        {
          sku: data.assetItems[0].sku, // Update with the correct value
        },
      ],
    };

    const response = await fetch(process.env.REACT_APP_API_URL + "/lead/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        lead: leadData,
      }),
    });

    const content = await response.json();
    setModalContent(content?.status?.success && content?.data ? SUCCESS : FETCH_ERROR);
    setModalIsOpen(true);
  };



  const dynamicInputs = () => {
    return (
      <>
        {data.brand_austria === "Velo" && <Dropdown optionList={webformData.sachets} setData={setData} data={data}
          fieldName={"sachets"} selectFirst={true} ></Dropdown>}

        <Dropdown optionList={webformData.category_before_austria} setData={setData} data={data}
          fieldName={"category_before_austria"} >Kategorie</Dropdown>

        {data.category_before_austria === "FMC" && <Dropdown optionList={webformData.FMC} setData={setData}
          data={data} fieldName={"category_austria"} >Kategorie Marke</Dropdown>}
        {data.category_before_austria === "THP" && <Dropdown optionList={webformData.THP} setData={setData}
          data={data} fieldName={"category_austria"} >Kategorie Marke</Dropdown>}
        {data.category_before_austria === "Oral" && <Dropdown optionList={webformData.oral} setData={setData}
          data={data} fieldName={"category_austria"} >Kategorie Marke</Dropdown>}
      </>
    )
  }

  const [errors, setErrors] = useState({})

  const focus = _.debounce((name) => {
    setTimeout(() => {
      document.querySelector("input[name='" + name + "']")?.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
      setTimeout(() => {
        document.querySelector("input[name='" + name + "']")?.focus()
        // console.log(name)
      }, 500)
    }, 300)
  }, 200)

  const validateInput = (i) => {
    const input = i
    const name = input.name
    const value = input.type === "checkbox" ? input.checked : input.value
    const regex = input.dataset?.regex || false
    const comment = input.dataset?.regexcomment || false

    // console.log(regex)

    // console.log(input.dataset)

    if (![name] || value === "" || value === false || value === "false") {
      let err = errors
      err[name] = "Pflichtfeld"
      setErrors({ ...err })
      // focus(name)

      return false
    } else if (regex && !new RegExp(regex).test(value)) {
      // console.log("wrong")
      let err = errors
      err[name] = comment ? comment : "Ungültige Eingabe"
      setErrors({ ...err })
      // focus(name)
      return false
    } else {
      let err = errors
      err[name] = false
      setErrors({ ...err })
      return true
    }
  }

  const calculateAge = (birthdate) => {
    const today = new Date();
    const birthdateObj = new Date(birthdate);
    const age = today.getFullYear() - birthdateObj.getFullYear();
    const monthDiff = today.getMonth() - birthdateObj.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthdateObj.getDate())) {
      return age - 1;
    }

    return age;
  };

  const validateForm = (e, callback, formClass = "") => {
    e.preventDefault()
    let wrongCounter = 0
    const inputs = document.querySelectorAll("form" + (formClass ? "." + formClass : "") + " input:required")

    if (inputs && inputs.length > 0) {
      for (let i = inputs.length - 1; i >= 0; i--) {
        const input = inputs[i];
        if (!validateInput(input)) {
          wrongCounter++
        }
      }

      const birthdateInput = document.querySelector("form" + (formClass ? "." + formClass : "") + " input[name='birthday']");
      const birthdateValue = data.birthday;

      const age = calculateAge(birthdateValue);

      if (age < 18) {
        setErrors((prev) => ({ ...prev, birthday: "Muss volljährig sein" }));
        wrongCounter++;
      } else {
        setErrors((prev) => ({ ...prev, birthday: false }));
      }

      // TEMPORARY

      // if (data.gender === "") {
      //   setErrors(prev => ({ ...prev, gender: "Required field" }))
      //   wrongCounter++
      // } else {
      //   setErrors(prev => ({ ...prev, gender: false }))
      // }

      // if (data.birthday === "") {
      //   setErrors(prev => ({ ...prev, birthday: "Required field" }))
      //   wrongCounter++
      // } else {
      //   setErrors(prev => ({ ...prev, birthday: false }))
      // }

      const focusArray = [...inputs].filter(e => errors[e.name] ?? false)

      if (focusArray?.[0]) {
        focus(focusArray[0]?.name)
      }

      if (wrongCounter === 0) {
        callback()
      }
    }
  }

  // chagne that

  useEffect(() => {
    if (errors.gender && data.gender.length !== 0) {
      setErrors(prev => ({ ...prev, gender: false }))
    }

    if (errors.birthday && data.birthday.length !== 0) {
      setErrors(prev => ({ ...prev, birthday: false }))
    }

  }, [data.gender, data.birthday])

  const inlineValidate = (e) => {
    const target = e.target;
    const targetName = target.name;
    let focus = loosedFocus;
    focus[targetName] = true;
    validateInput(e.target);

    if (targetName === "birthday" && loosedFocus["birthday"]) {
      const age = calculateAge(target.value);

      if (age < 18) {
        setErrors((prev) => ({ ...prev, birthday: "Muss volljährig sein" }));
      } else {
        setErrors((prev) => ({ ...prev, birthday: false }));
      }
    }

    setLoosedFocus(focus);
  };

  const scrollTop = () => {
    webFormRef?.current?.scrollIntoView({ top: 0, left: 0, behavior: 'smooth' })
  }

  const resetForm = () => {
    setData({
      store_id_austria: "",
      promotor_id_austria: "",
      gender: "Keine Angabe",
      firstname: "",
      lastname: "",
      email: "",
      intCode: "",
      areaCode: "",
      number: "",
      birthday: "",
      brand_austria: "",
      category_before_austria: "",
      category_austria: "",
      otp: "",
      "digital_data_agreement": false,
      "terms_austria": true,
      "marketing_consents_austria": true,
      "policy_agreement": true,
      "oral_status_austria": "Prospect",
      "hypercareOptIn": true,
      "leadsource_austria": "Promoter",
      "countryId": "1014",
      "age_verified": true,
      "data_privacy_agreement": true,
      "terms_of_service_accepted": true,
      "marketing_consent": true,
      "asset_source": "Hostess",
      "assetItems": [
        {
          "sku": "none",
        },
      ]
    })
    setModalContent("")
  }

  const modalContentSwitch = () => {
    switch (modalContent) {
      case (FETCH_ERROR): {
        return (
          <div className='otp-wrapper'>
            <h1>Etwas ist schiefgegangen</h1>
            <button onClick={() => { setModalIsOpen(false); setModalContent(""); }}>Schließen</button>
          </div>
        )
      }

      case (GET_OTP): {
        return (
          <div className="otp-wrapper">
            <h1>Code einfügen</h1>
            <input type="text" placeholder='SMS CODE' value={data.otp}
              onChange={e => setData(prev => ({ ...prev, otp: e.target.value }))} />
            <button onClick={() => { setModalIsOpen(false); handleSubmit() }}>Erledigt</button>
          </div>
        )
      }

      case (SUCCESS): {
        return (
          <div className="otp-wrapper">
            <h2>Willkommen bei VELO! </h2>
            <p>Wenn Du deinen Account nicht bereits per SMS bestätigt hast bitte schau in Deine Inbox und klicke auf den Bestätigungslink. Wir freuen uns darauf, dir genau auf Dich zugeschnittene Benachrichtigungen über laufende und kommende Aktionen und weitere Infos rund um VELO zuzuschicken!</p>
            <h2>Bis bald! </h2>
            <button onClick={() => { setModalIsOpen(false); resetForm() }}>Zurück</button>
          </div>
        )
      }

      default: {
        return (
          <div className='otp-wrapper'>
            <h1>Etwas ist schiefgegangen</h1>
            <button onClick={() => { setModalIsOpen(false); setModalContent(""); }}>Schließen</button>
          </div>
        )
      }
    }
  }

  // improvments
  // Dropdown data into context
  // automaic validation for Dropdowns
  // component for input with all the data
  // better naming for fieldNames with automatic assigment of possible values
  // components Input and Dropdown with automatic error under button/input

  return (
    <section className='webform' ref={webFormRef}>
      <div className='logo' />
      <form>
        <Dropdown optionList={webformData.store_id_austria} setData={setData} data={data} fieldName={"store_id_austria"}>Kanal</Dropdown>

        <input name='promotor_id_austria' type="text" placeholder='Promoter-ID' data-regex={webformData.hostessCodes} value={data.promotor_id_austria} onChange={e => { if (loosedFocus["promotor_id_austria"]) inlineValidate(e); setData(prev => ({ ...prev, promotor_id_austria: e.target.value })) }} onBlur={(e) => { if (!loosedFocus["promotor_id_austria"]) inlineValidate(e) }} required />
        {errors?.promotor_id_austria && <p className='error'>{errors?.promotor_id_austria}</p>}

        <Dropdown optionList={webformData.gender} setData={setData} data={data} fieldName={"gender"} selectFirst={true} ></Dropdown>
        {errors?.gender && <p className='error'>{errors?.gender}</p>}

        <input name='firstname' type="text" placeholder='Vorname' value={data.firstname} onChange={e => { if (loosedFocus["firstname"]) inlineValidate(e); setData(prev => ({ ...prev, firstname: e.target.value })) }} onBlur={(e) => { if (!loosedFocus["firstname"]) inlineValidate(e) }} required />
        {errors?.firstname && <p className='error'>{errors?.firstname}</p>}

        <input name='lastname' type="text" placeholder='Nachname' value={data.lastname} onChange={e => setData(prev => ({ ...prev, lastname: e.target.value }))} />
        {errors?.lastname && <p className='error'>{errors?.lastname}</p>}

        <input name='email' type="text" placeholder='E-mail' value={data.email} onChange={e => { if (loosedFocus["email"]) inlineValidate(e); setData(prev => ({ ...prev, email: e.target.value })) }} onBlur={(e) => { if (!loosedFocus["email"]) inlineValidate(e) }} required
          data-regex='^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$' />
        {errors?.email && <p className='error'>{errors?.email}</p>}

        <div className="phone-number-wrapper">
          <Dropdown optionList={webformData.mobilePrefixes} selectFirst={true} setData={setData} data={data} fieldName={"intCode"}
          />
          <input
            type="text"
            placeholder='Mobilnummer'
            value={data.number}
            name="number"
            onChange={e => {
              const inputValue = e.target.value;
              if (/^[0-9]*$/.test(inputValue) || inputValue === "") {
                setData(prev => ({ ...prev, number: inputValue }));
              }
            }}
          />
        </div>
        {errors?.number && <p className='error number'>{errors?.number}</p>}

        <Calendar data={data} setData={setData} fieldName="birthday"  >Geburtsdatum</Calendar>
        {errors?.birthday && <p className='error'>{errors?.birthday}</p>}

        <Dropdown optionList={webformData.brand} setData={setData} data={data} fieldName={"brand_austria"} selectFirst={true}></Dropdown>

        <>{dynamicInputs()}</>

        <div className="agreements">
          <label htmlFor='digital_data_agreement' className="checkbox-wrapper">
            <div className={`checkbox ${errors["digital_data_agreement"] ? "error" : ""}`}>
              <input type="checkbox" id="digital_data_agreement" name="digital_data_agreement" checked={data.digital_data_agreement} onChange={e => { if (loosedFocus["digital_data_agreement"]) inlineValidate(e); setData(prev => ({ ...prev, digital_data_agreement: !prev.digital_data_agreement })) }} onBlur={(e) => { if (!loosedFocus["digital_data_agreement"]) inlineValidate(e) }} required />
            </div>
            <p>Ja, ich möchte Newsletter, individualisierte Angebote und Kundenumfragen per E-Mail von der British American Tobacco (Austria) GmbH, Dr.-Karl-Lueger-Platz 5, 1010 Wien („BAT“) erhalten.
              Ich bin damit einverstanden, dass BAT zu diesen Zwecken meine persönlichen Produkt-Präferenzen auf Basis der von BAT über mich verarbeiteten Daten in einem individuellen Profil zusammenführt.
              Diese Einwilligungen kannst du jederzeit mit Wirkung für die Zukunft widerrufen. Bei E-Mail-Werbung befindet sich ein entsprechender Abmeldelink in jeder E-Mail. Weitere Informationen
              hierzu findest Du in unseren <a href={Statute1} target="_blank" rel="noopener noreferrer">Datenschutzbestimmungen</a>.</p>

            <p className="info">
              Es gelten unsere <a href={Statute2} target="_blank" rel="noopener noreferrer">Nutzungsbedingungen</a>.
            </p>
          </label>
        </div>

        <button onClick={e => validateForm(e, () => { if (data.number.length !== 0) { getOTP() } else { handleSubmit() }; scrollTop() })}>Senden</button>
      </form>
      <Modal isOpen={modalIsOpen} setIsOpen={setModalIsOpen} resetForm={resetForm}>
        <>{modalContentSwitch()}</>
      </Modal>
    </section>
  )
}
